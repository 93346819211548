<template>
  <div class="card-tm">
    <div class="card">
      <div class="card-body gradiant-bg py-lg-5">
        <div class="container">
          <div class="row justify-content-center align-items-center flex-column gap-3 py-3 flex-lg-row">
            <div class="col-12 col-lg-5 register login">
              <div class="card rounded-4 shadow text-center p-3">
                <div class="card-body">
                  <div>
                    <h6 class="fs-3 fw-600">Login</h6>
                    <p class="font-light-gray fs-14">
                      Welcome Back! Login your Trading account.
                    </p>
                  </div>
                  <div class=" flat-tabs text-start">
                    <div class="content-inner">
                      <div class="content-tab">
                        <form @submit.prevent="markRecaptchaAsVerified">
                          <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active " id="pills-email" role="tabpanel"
                              aria-labelledby="pills-email-tab">
                              <div class="form-group" :class="{ 'form-group--error': $v.form.email.$error }">
                                <!-- <label for="exampleInputEmail1">Email Address</label> -->
                                <input id="exampleInputEmail1" autocomplete="off" type="email" class="form-control "
                                  placeholder="Enter your email" v-model.trim="$v.form.email.$model">
                              </div>
                            </div>
                          </div>


                          <div class="form-group s1" :class="{ 'form-group--error': $v.form.password.$error }">
                            <!-- <label>Password </label> -->

                            <div class="w-100 input-group position-relative">

                              <input id="exampleInputPassword1" autocomplete="off" :type="hidden ? 'password' : 'text'"
                                class="form-control" placeholder="Enter your password"
                                v-model.trim="$v.form.password.$model" />
                              <span class="input-group-text" id="basic-addon1">
                                <!-- hide-show icon  -->
                                <div class="hide_show">
                                  <button type="button" class="btn p-0 shadow-none"><span @click="hidden = !hidden"
                                      v-html="hidden ? show_password : hide_password"></span></button>
                                </div>
                              </span>
                            </div>

                          </div>


                          <!-- recaptcha box  -->
                          <div class="col-12">
                            <div class="recaptcha-box mb-3 fs-14">
                              <vue-recaptcha ref="recaptcha" size="invisible" @verify="submitForm"
                                @expired="loginForm.recaptchaVerified = false" :sitekey="captcha_site_key">
                              </vue-recaptcha>

                            </div>
                          </div>
                          <div class="form-group form-check">
                            <div>
                              <input type="checkbox" class="form-check-input">
                              <label class="form-check-label">Remember Me</label>
                            </div>
                            <router-link to="/forgetpassword">Forgot Password?</router-link>
                          </div>
                          <div class="col-12">
                            <div class="recaptcha-box mb-3 fs-14">
                              <vue-recaptcha ref="recaptcha" size="invisible" @verify="submitForm"
                                @expired="loginForm.recaptchaVerified = false" :sitekey="captcha_site_key">
                              </vue-recaptcha>
                            </div>
                          </div>
                          <div class="form-group">
                            <!-- loader  -->
                            <button v-if="loading" type="button" class="btn-action">
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                            <!-- sign-btn  -->
                            <button v-else type="submit" class="btn-action">
                              <div class="d-flex justify-content-between">
                                <span>Login</span>
                                <span><i class="fas fa-long-arrow-right"></i></span>
                              </div>
                            </button>
                          </div>
                          <div class="bottom">
                            <p class="desc fs-14 fw-500">Don't have an account? </p>
                            <router-link to="/register">Register</router-link>
                          </div>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-lg-6">
              <img :src="require(`../../../public/assets/images/layout/auth-bg.png`)" class="img-fluid" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiClass from "../../api/api";
import {
  required,
  email
} from "vuelidate/lib/validators";

import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "Login",
  components: {
    VueRecaptcha
  },

  data() {
    return {
      steps: [
        { title: 'User Friendly Interface', subtitle: 'Simple interface taht make it easy for user trade' },
        { title: 'Wide Range of Cryptocurrency', subtitle: 'A diverse selection of cryptocurrency available for trading' },
        { title: 'High Security Measures', subtitle: 'Adv. security protocols, including two factor authentication' },
      ],
      login_svg: require('@/assets/auth_img/login-img.svg'),
      loading: false,
      hidden: true,
      show: false,
      show_password: '<i class="far fs-5 mt-2 fa-eye"></i>',
      hide_password: '<i class="far fs-5 mt-2 fa-eye-slash"></i>',
      submitted: false,
      loginType: "",
      form: {
        email: "",
        mobile: "",
        password: "",
        country_code: "",
        country_calling_code: "",
        captcha_response: "",
      },
      loginForm: {
        recaptchaVerified: false,
      },

      captcha_site_key: ApiClass.CAPTCHA_SITE_KEY,
      noImage: false,
    };
  },
  mounted() {
    this.noImage = (this.$route.params.noImg) ? (this.$route.params.noImg) : false;
    this.form.loginType = "email";
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required
      },
    },

  },
  methods: {

    async submitForm(recaptchaToken) {
      this.loginForm.recaptchaVerified = true;
      this.form.captcha_response = recaptchaToken;
      this.submitted = true;

      if (!this.loginForm.recaptchaVerified) {
        return;
      }

      this.submitted = false;
      this.loading = true;
      var result = await ApiClass.postRequest("login", false, this.form);

      if (result.data.status_code == 1) {
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });

        this.form.email = this.form.password = this.form.captcha_response = "";
        // console.log('here',result.data)
        // console.log(result.data.data.token);
        if (result.data.data.token) {
          localStorage.setItem("token", result.data.data.token);
          localStorage.setItem("user", JSON.stringify(result.data.data.user));
          await new Promise((resolve) => setTimeout(resolve, 1000)); // for slow down
          this.loading = false;
          this.$store.commit("SET_USER", JSON.stringify(result.data.data.user));
          return (window.location.href =
            window.location.origin + "/");
        } else {
          return this.$router.push({
            name: "Verify",
            params: {
              data: result.data.data,
            },
          });
        }
      } else {
        this.loading = false;
        this.$swal({
          toast: true,
          position: "top-end",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 3000,
        });
        this.$refs.recaptcha.reset();
        this.form.captcha_response = "";
      }
    },

    clickEmailTab() {
      this.form.loginType = "email";
    },

    markRecaptchaAsVerified() {
      this.$refs.recaptcha.execute();
    },
  },
}
</script>

<style>
/* .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff !important;
} */
</style>